import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-doveSiamo"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function DoveSiamo() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t("page-doveSiamo:title")} />
            <Container>
                <StaticImage
                    src="../../../assets/images/assisi-e-papaveri.jpg"
                    alt={t("page-doveSiamo:title")}
                    className="rounded-4xl"
                    aspectRatio={16 / 10}
                    layout="fullWidth"
                    transformOptions={{ fit: "cover" }}
                />
                <h1 className="mt-8">{t("page-doveSiamo:title")}</h1>
                <p>{t("page-doveSiamo:description:1")}</p>
                <p>{t("page-doveSiamo:description:2")}</p>
                <p>{t("page-doveSiamo:description:3")}</p>
                <iframe
                    className="w-full mt-8"
                    src="https://www.google.com/maps/d/u/0/embed?mid=1UYqZLOada8D08cPD_H5vXyj8Wfslm4n3"
                    height="600px"
                    title={t("page-doveSiamo:title")}
                ></iframe>
            </Container>
        </Layout>
    );
}
